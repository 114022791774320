// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Font family and sizes
$font-primary: 'GT Eesti Display', sans-serif;
$font-mono: 'Apercu Mono', monospace;

// Colors
$color-primary: #ff4d00;
$color-primary-marrow: #fecfba;
$color-primary-black: #221f1d;

$color-secondary-brush: #123134;
$color-secondary-brush-light: #f8f8f8;
$color-secondary-iris: #493e3d;
$color-secondary-ice: #a6bec3;
$color-secondary-gauze: #e2e2e0;

$color-grey: #d7d7d5;
$color-black: #000;
$color-white: #fff;

$color-success: #71cc6d;
$color-error: #e94c3e;

// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1441px;
$breakpoint-xxxl: 1921px;

// Spacing
$space-grid: 15px;
$space-tiny: 0.8rem;
$space-xxs: 1.6rem;
$space-xs: 2.4rem;
$space-sm: 3.2rem;
$space-sm2: 4rem;
$space-md: 4.8rem;
$space-lg: 6.4rem;
$space-lg2: 7.2rem;
$space-xl: 9.6rem;
$space-xxl: 12.8rem;
$space-xxxl: 14.4rem;
$space-huge: 16rem;

// Transitions
$transition-time: 0.3s;
$transition-easing: ease-in-out;

// Layout
$header-height: 7rem;

// Vendor overrides
$grid-gutter-width: 3rem;

$grid-breakpoints: (
  xs: 0,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
  xxl: $breakpoint-xxl,
  xxxl: $breakpoint-xxxl
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px,
  xxxl: 1440px
);
